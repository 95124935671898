.quote-h2 {
  text-transform: uppercase;
  color: #00a651;
  display: flex;
  justify-content: center;
  font-size: 35px;
}


.divider {
  position: relative;
}

.divider:after {
  position: absolute;
  content: "";
  width: 15%;
  height: 1px;
  border-bottom: 3px solid #737476;
  bottom: 0;
}

.card {
  border: none;
}

.card-footer {
  display: none;
}

button.btn.btn-questionnaire {
  background-color: #00a651 !important;
  border-color: #00a651 !important;
  padding: 11px 8% 11px 8%;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
}

@media only screen and (max-width: 500px) {
  button.btn.btn-questionnaire {
    /* max-width: 160px; */
    margin: 0 10px;
  }
}

button.btn.btn-questionnaire-outline {
  background-color: transparent !important;
  border-color: #00a651 !important;
  padding: 11px 8% 11px 8%;
  font-size: 17px;
  font-weight: bold;
  color: #00a651;
  border: 2px solid;
}

button.btn.btn-questionnaire:hover {
  color: #ffffff;
}

button.btn.btn-questionnaire-outline:hover {
  background-color: #00a651 !important;
  border-color: #00a651 !important;
  padding: 11px 8% 11px 8%;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  border: 2px solid;
}

button.btn.btn-questionnaire:only-child,
button.btn.btn-questionnaire-outline:only-child {
  margin: auto;
}

.back {
  padding-left: 8.5%;
  padding-right: 8.5%;
}

.radio-btns {
  display: flex;
  gap: 6.5rem;
  padding-top: 30px;
}

label.f-title,
label.f-title2 {
  color: #6f7176;
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 17px;
  position: relative;
  left: 45%;
}

label.f-title {
  left: 45%;
}

label.f-title2 {
  left: 30%;
}

.radio-btns:hover,
.input-group:hover {
  background-color: #fcfcfb;
}

input#residence_province_code {
  position: relative;
  left: 80%;
  width: 295%;
}

select.form-select {
  font-weight: 700;
  color: black;
  background-color: #fbfafa;
  border: 2px solid #cbcbcd;
  font-weight: 700;
  color: black;
  font-size: 17px;
  margin-bottom: 10px;
}

.footer-buttons {
  margin: 40px 0 100px 0;
}