.quote-h1,
.quote-h6 {
    color: #00A651;
    display: flex;
    justify-content: center;
}

.quote-h1 {
    margin-top: 0%;
}

.quote-h6 {
    font-weight: 700;
    position: relative;
}

.quote-h6:after {
    position: absolute;
    content: '';
    width: 5%;
    height: 1px;
    border-bottom: 3px solid #737476;
    bottom: -2px;
    left: auto;
    right: auto;
    /* margin-left: -7.5%; */
}

.product {
    padding: 10px 5px;
    border: 2px solid #6D6E70;
    border-radius: 15px;
}

.product:hover {
    outline: 3px solid #00A651;
    background-color: #F6F5F4;
}

.row-gap {
    column-gap: 40px;
    margin-top: 40px;
}

.container {
    margin-top: 3%;
}

.h5 {
    color: #000000;
}

.svg {
    height: 80px;
    margin: 10px 0;
}

.second-row {
    margin-bottom: 40px;
}

.btn-continue {
    font-size: 22px;
    font-weight: 600;
    background-color: #00A451 !important;
    border-color: #00A451 !important;
    border-radius: 10px;
    width: 300px;
    padding: 10px 0;
    margin-top: 20px;
}

.footer-button {
    margin: 10px 0 10px 0;
}